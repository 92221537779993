* {
	@apply antialiased font-normal;
}

h1, h2, h3, h4, h5, h6, button {
	@apply font-title;
}

[type='checkbox'],
[type='radio'],
select {
	@apply appearance-none focus:outline-none focus:shadow-none shadow-none focus:ring-0;
}

.hide-scrollbar {
	@include hideScrollbar;
}